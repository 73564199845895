/**
 * CTableForm 表格式表单
 * @author Tevin、chensi
 */

import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { Tools } from '@components/common/Tools';
import { BTableFormContext } from '@components/plugins/tableForm/bases/BTableFormContext';
import { CTableFormRowBtns } from '@components/plugins/tableForm/CTableFormRowBtns';
import { CTableFormTable } from '@components/plugins/tableForm/CTableFormTable';
import { CTableFormArrangement } from '@components/plugins/tableForm/CTableFormArrangement';
import { CText } from '@components/fragments/text/CText';
import './cTableForm.scss';

export class CTableForm extends React.Component {
    static propTypes = {
        // 滚动高度
        scrollHeight: PropTypes.number,
        // 自动匹配最大高度
        autoMaxHeigh: PropTypes.bool,
        // 自动高度时，需要减去的额外高度
        trimHeight: PropTypes.number,
        // 表格标题
        title: PropTypes.string,
        // 加载中状态
        loading: PropTypes.bool,
        // 表格列
        defaultColumns: PropTypes.array,
        // 默认数据
        defaultDataResources: PropTypes.array,
        // 行数为空时，是否允许通过验证
        emptyPassAllow: PropTypes.bool,
        // 主列自动聚焦，输入需要聚焦的键名开启
        //  主列为空时，点其他列的表单元素时聚焦主列
        primaryFocusColumn: PropTypes.string,
        // 数据清空是否锁定（只能整体改，与行无关）
        cleanAllLocked: PropTypes.bool,
        // 行操作按钮禁用
        rowBtnDisabled: PropTypes.bool,
        // 自定义更多行操作按钮
        rowBtnAddon: PropTypes.node,
        // 回车时，自动对下一个表单项聚焦，有三种模式
        //  第一种，布尔值（默认为布尔值且为true），表示跳下一表单项时，不跳过任何列（除非禁用了）
        //  第二种，数组，表示允许聚焦的列，[dataIndex1, dataIndex2]，未设置的列将被忽略
        //  第三种，字符串'required'，按必填项设置允许聚焦的列，非必填项忽略
        autoNextFoucs: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.array,
            PropTypes.string,
        ]),
        // 选择回调（可控制行操作按钮锁定）
        onSelected: PropTypes.func,
        // 变化回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
        defaultColumns: [],
        defaultDataResources: [],
        emptyPassAllow: false,
        cleanAllLocked: false,
        rowBtnDisabled: false,
        rowBtnAddon: null,
        autoNextFoucs: true,
        onSelected: () => null,
        onChange: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            // 源数据
            dataResource: [],
            // 选择的行数据
            selectedRowData: {},
            // 选中表格中的行数据下标
            selectedRowIndex: -1,
            // 选中行，操作锁定
            selectedBtnLocked: {
                add: false,
                remove: false,
                insert: false,
                copy: false,
            },
            // 表单验证失败的单元格 {rowKey: [dataIndex1, dataIndex2]}
            errorCells: {},
            // 当前焦点单元格 [rowKey, dataIndex]
            focusCell: ['', ''],
        };
        this.$refs = {
            rowBtns: null,
            arrangment: null,
        };
        this._data = {
            formId: Tools.createGUID(),
            validateds: [],
        };
    }

    // 获取当前表格数据
    _copyDataResource() {
        const nextDataRes = [];
        this.state.dataResource.forEach(rowRes => {
            const { key, ...resetRow } = rowRes;
            // 过滤空行
            if (Tools.isEmptyObject(resetRow, 'checkValue')) {
                return;
            }
            nextDataRes.push(resetRow);
        });
        return nextDataRes;
    }

    // 表单行或数据变更
    _handleRowChange(changed) {
        const { nextDatas, ...resetChanged } = changed;
        this.setState(
            {
                dataResource: nextDatas,
            },
            () => {
                this.props.onChange(this._copyDataResource(), resetChanged);
            },
        );
    }

    // 选中或取消选中一行
    _handleRowSelected({ index, record, directly }) {
        this.setState({
            selectedRowIndex: index,
            selectedRowData: record,
        });
        this.props.onSelected({
            // 选中数据
            selected: { record, index },
            // 是否为直接选中，manual 用户选择、automatic 行操作引起的自动选中
            directly,
            // 按钮锁定控制
            //  lockeds: {add,remove,insert,copy}
            setRowBtnLock: lockeds => {
                this.setState({
                    selectedBtnLocked: {
                        ...this.state.selectedBtnLocked,
                        ...lockeds,
                    },
                });
            },
        });
    }

    // 取消行选中
    _handleCancelRowSelected() {
        if (this.state.focusCell[0] && this.state.focusCell[1]) {
            this.setState({
                focusCell: ['', ''],
            });
        }
        if (this.state.selectedRowIndex < 0) {
            return;
        }
        this._handleRowSelected({
            index: -1,
            record: {},
            directly: 'automatic',
        });
    }

    // 子级表单获取值
    _handleGetItemValue(rowIndex, dataIndex) {
        return this.state.dataResource[rowIndex][dataIndex];
    }

    // 子级表单变更值
    _handleSetItemValue(rowIndex, dataIndex, evt) {
        const nextDataRes = [...this.state.dataResource];
        nextDataRes[rowIndex] = { ...this.state.dataResource[rowIndex] };
        const oldValue = this.state.dataResource[rowIndex][dataIndex];
        nextDataRes[rowIndex][dataIndex] = this._getEventValue(evt);
        this.setState(
            {
                dataResource: nextDataRes,
            },
            () =>
                this.props.onChange(this._copyDataResource(), {
                    type: 'valueChange',
                    rowIndex,
                    dataIndex,
                    oldValue,
                    newValue: nextDataRes[rowIndex][dataIndex],
                }),
        );
    }

    // 将表单元素输入操作平化为值
    _getEventValue(evt) {
        if (!evt) {
            return evt;
        }
        if (!Tools.isObject(evt)) {
            return evt;
        }
        if (
            Tools.isFunction(evt.preventDefault) &&
            Tools.isFunction(evt.stopPropagation) &&
            evt.target
        ) {
            // 其值为checked的元素
            if (evt.target.valueType === 'checked') {
                return evt.target.checked;
            }
            return evt.target.value;
        }
        return evt;
    }

    // 设置表单验证报错红框显示
    _handleFormErrors(validated) {
        this._data.validateds.push(validated);
        // 防抖设置所有报错
        Tools.debounce(() => {
            const nextErrorCell = { ...this.state.errorCells };
            this._data.validateds.forEach(validated => {
                // 验证通过的
                if (validated.passed) {
                    // 本行没有报错跳过
                    if (typeof nextErrorCell[validated.position[0]] === 'undefined') {
                        return;
                    }
                    const alreadyIndex = nextErrorCell[validated.position[0]].findIndex(
                        dataIndex => dataIndex === validated.position[1],
                    );
                    // 删除本行已改正确的项
                    if (alreadyIndex >= 0) {
                        nextErrorCell[validated.position[0]].splice(alreadyIndex, 1);
                    }
                }
                // 验证失败的
                else {
                    if (typeof nextErrorCell[validated.position[0]] === 'undefined') {
                        nextErrorCell[validated.position[0]] = [];
                    }
                    // 记录验证报错字段
                    nextErrorCell[validated.position[0]].push(validated.position[1]);
                }
            });
            this.setState({
                errorCells: nextErrorCell,
            });
            // 完成一次后重置
            this._data.validateds = [];
        }, 100)(this._data.formId);
    }

    // 设置表格单元格聚焦
    _handleCellFocus(cell) {
        this.setState({
            focusCell: [cell.rowKey, cell.dataIndex],
        });
    }

    // 设置表格单元格失焦
    _handleCellBlur(cell) {
        if (
            this.state.focusCell[0] === cell.rowKey &&
            this.state.focusCell[1] === cell.dataIndex
        ) {
            this.setState({
                focusCell: ['', ''],
            });
        }
    }

    _renderTableTitle() {
        return current => (
            <div className="c-table-form-header">
                {this.props.title ? (
                    <div className="c-table-form-header-title">
                        {this.props.title}
                        <CText type="ignore">|</CText>
                    </div>
                ) : (
                    ''
                )}
                <CTableFormRowBtns
                    dataResource={this.state.dataResource}
                    selectedRowIndex={this.state.selectedRowIndex}
                    selectedRowData={this.state.selectedRowData}
                    selectedBtnLocked={this.state.selectedBtnLocked}
                    cleanAllLocked={this.props.cleanAllLocked}
                    rowBtnDisabled={this.props.rowBtnDisabled}
                    rowBtnAddon={this.props.rowBtnAddon}
                    ref={elm => (this.$refs.rowBtns = elm)}
                    onRowChange={evt => this._handleRowChange(evt)}
                    onRowSelected={evt => this._handleRowSelected(evt)}
                />
            </div>
        );
    }

    _renderColumns() {
        return (this.props.defaultColumns || []).map((column, colIndex) => {
            if (column.isFormItem) {
                return {
                    ...column,
                    render: (text, record, index) =>
                        this.$refs.arrangment.$renderColumn({
                            column,
                            rowIndex: index,
                            colIndex,
                            text,
                            record,
                        }),
                };
            } else {
                return column;
            }
        });
    }

    render() {
        const providerValue = {
            getItemValue: (...evt) => this._handleGetItemValue(...evt),
            setItemValue: (...evt) => this._handleSetItemValue(...evt),
            setItemValidated: (...evt) => this._handleFormErrors(...evt),
            setCellFocus: (...evt) => this._handleCellFocus(...evt),
            setCellBlur: (...evt) => this._handleCellBlur(...evt),
        };
        return (
            <div className="c-table-form">
                <BTableFormContext.Provider value={providerValue}>
                    <CTableFormArrangement
                        defaultColumns={this.props.defaultColumns}
                        primaryFocusColumn={this.props.primaryFocusColumn}
                        dataResource={this.state.dataResource}
                        selectedRowIndex={this.state.selectedRowIndex}
                        focusCell={this.state.focusCell}
                        autoNextFoucs={this.props.autoNextFoucs}
                        ref={elm => (this.$refs.arrangment = elm)}
                        onSelectRow={evt =>
                            this._handleRowSelected({ ...evt, directly: 'automatic' })
                        }
                        onCancelRowSelected={evt => this._handleCancelRowSelected()}
                        onAddNewRow={evt => this.$refs.rowBtns.$addNew()}
                    >
                        <CTableFormTable
                            title={this._renderTableTitle()}
                            defaultColumns={this._renderColumns()}
                            loading={this.props.loading}
                            dataResource={this.state.dataResource}
                            errorCells={this.state.errorCells}
                            focusCell={this.state.focusCell}
                            rowClassName={(record, index) =>
                                this.state.selectedRowIndex === index ? 'selected' : ''
                            }
                            onRow={(record, index) => ({
                                onClick: () =>
                                    this._handleRowSelected({
                                        index,
                                        record,
                                        directly: 'manual',
                                    }),
                            })}
                        />
                    </CTableFormArrangement>
                </BTableFormContext.Provider>
            </div>
        );
    }

    $getFormData() {
        return this._copyDataResource();
    }

    // 重设所有数据，清除选中
    $resetFormData(resource) {
        if (!resource || !Tools.isArray(resource)) {
            resource = [];
        }
        this.setState({
            selectedRowIndex: -1,
            selectedRowData: {},
            dataResource: resource.map((rowData, index) => ({
                ...rowData,
                key: Tools.createGUID(),
            })),
        });
    }

    // 单行更新，不清除选中
    $resetFormRow(rowIndex, rowData) {
        if (!rowIndex && rowIndex !== 0) {
            console.warn('CTableForm：请指定要更新的行！');
            return;
        }
        if (!rowData || !Tools.isObject(rowData)) {
            console.warn('CTableForm：更新行，格式有误！');
            return;
        }
        const nextDataRes = [...this.state.dataResource];
        nextDataRes[rowIndex] = {
            ...rowData,
            key: this.state.dataResource[rowIndex].key || Tools.createGUID(),
        };
        this.setState({
            dataResource: nextDataRes,
        });
        // 本行更新100毫秒后，主动补偿一次焦点（针对异步更新）
        setTimeout(() => {
            if (this.state.dataResource[rowIndex].key === this.state.focusCell[0]) {
                this.$refs.arrangment.$setCellFocus(this.state.focusCell);
            }
        }, 100);
    }

    // 单元格更新，不清除选中，不影响其他表单项
    $resetFormCell(rowIndex, dataIndex, value) {
        if (!rowIndex && rowIndex !== 0) {
            console.warn('CTableForm：请指定要更新的行！');
            return;
        }
        if (!dataIndex || !Tools.isString(dataIndex)) {
            console.warn('CTableForm：请指定要更新的列！');
            return;
        }
        if (!this.state.dataResource[rowIndex]) {
            console.warn('CTableForm：需更新的行不存在！');
            return;
        }
        const nextDataRes = [...this.state.dataResource];
        nextDataRes[rowIndex][dataIndex] = value;
        this.setState({
            dataResource: nextDataRes,
        });
    }

    // 表单验证
    $validateFields(callback) {
        // 无数据
        if (this.state.dataResource.length === 0) {
            if (!this.props.emptyPassAllow) {
                message.error(
                    '请添加' + (this.props.title ? this.props.title : '数据') + '！',
                );
            }
            callback &&
                callback({
                    passed: this.props.emptyPassAllow,
                    rowsCount: 0,
                });
            return;
        }
        // 有数据
        const errorItems = [];
        const formItems = this.$refs.arrangment.$getFormItemsArray();
        let validateCount = 0;
        formItems.forEach(formRow => {
            formRow.forEach(formItem => {
                validateCount++; // 验证计数加1
                formItem.elm.$validate(err => {
                    validateCount--; // 验证计数减1
                    if (err) {
                        errorItems.push(err);
                    }
                    // 验证完成后，如果有报错
                    if (validateCount === 0) {
                        if (errorItems.length > 0) {
                            message.error(errorItems[0].errorMsg);
                            callback &&
                                callback({
                                    passed: false,
                                    rowsCount: this.state.dataResource.length,
                                    errsCount: errorItems.length,
                                    errors: errorItems,
                                });
                        } else {
                            callback &&
                                callback({
                                    passed: true,
                                    rowsCount: this.state.dataResource.length,
                                });
                        }
                    }
                });
            });
        });
    }

    // 开始聚焦输入
    $startFocus() {
        if (this.state.dataResource.length === 0) {
            this.$refs.rowBtns.$addNew();
            // 等待添加完成后，再聚焦
            setTimeout(() => {
                this.$refs.arrangment.$setFirstFocus();
            }, 20);
        } else {
            this.$refs.arrangment.$setFirstFocus();
        }
    }
}

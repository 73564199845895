/**
 * CTableFormArrangement 表格式表单-单元格排列管理器
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import {} from 'antd';
import { Tools } from '@components/common/Tools';
import { CTableFormItem } from '@components/plugins/tableForm/CTableFormItem';

export class CTableFormArrangement extends React.Component {
    static propTypes = {
        // 表格列
        defaultColumns: PropTypes.array,
        // 自动聚焦主列
        primaryFocusColumn: PropTypes.string,
        // 源数据
        dataResource: PropTypes.array,
        // 当前选中行
        selectedRowIndex: PropTypes.number,
        // 聚焦单元格
        focusCell: PropTypes.array,
        // 自动下一个聚焦
        autoNextFoucs: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.array,
            PropTypes.string,
        ]),
        // 通知选中新行（焦点调度引起）
        onSelectRow: PropTypes.func,
        // 取消选择回调
        //  触发条件：点击的元素，既不在表单区域内，也不在弹窗上
        //  注意！表单项弹窗，必须使用 getPopupContainer 方法挂载到元素 #dialogs 内！！！
        onCancelRowSelected: PropTypes.func,
        // 发起添加新行
        onAddNewRow: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
        this._data = {};
        this.$refs = {
            autoNextFoucs: [],
            arrangment: null,
            dialog: null,
            primaryColumns: [],
            formItems: [],
        };
        this._listeners = {};
    }

    componentDidMount() {
        // 取消选中
        this._listeners.onDocClick = evt => {
            // 如果表单已从渲染树剥离，但是未卸载（比如弹窗关闭），跳过
            if (!this.$refs.arrangment) {
                return;
            }
            // 如果在表单区域内，跳过
            if (this.$refs.arrangment.contains(evt.target)) {
                return;
            }
            if (!this.$refs.dialog) {
                this.$refs.dialog = document.querySelector('#dialogs');
            }
            // 如果在弹窗上，跳过
            if (this.$refs.dialog && this.$refs.dialog.contains(evt.target)) {
                return;
            }
            // 既不在区域内，也不在弹窗上，取消选中
            this.props.onCancelRowSelected();
        };
        document.addEventListener('click', this._listeners.onDocClick, true);
        this._updateAutoNextFoucs();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this._listeners.onDocClick);
    }

    _updateAutoNextFoucs() {
        // 如果自动下一个聚焦被关闭，不允许调度
        if (!this.props.autoNextFoucs) {
            return;
        }
        // 按必填自动聚焦
        if (this.props.autoNextFoucs === 'required') {
            this._data.autoNextFoucs = this.props.defaultColumns
                .map(column => {
                    if (!column.isFormItem) {
                        return false;
                    }
                    if (!column.rules || !column.rules.length) {
                        return false;
                    }
                    for (let rule of column.rules) {
                        if (rule.required) {
                            return column.dataIndex;
                        }
                    }
                })
                .filter(Boolean);
        }
        // 按设置自动聚焦
        else if (
            Tools.isArray(this.props.autoNextFoucs) &&
            this.props.autoNextFoucs.length
        ) {
            this._data.autoNextFoucs = [...this.props.autoNextFoucs];
        }
        // 按所有项自动聚焦
        else if (this.props.autoNextFoucs) {
            this._data.autoNextFoucs = this.props.defaultColumns
                .map(column => (column.isFormItem ? column.dataIndex : false))
                .filter(Boolean);
        }
    }

    // 判断是否对主列进行聚焦
    _handlePrimaryFocus({ rowIndex, dataIndex }) {
        const primaryKey = this.props.primaryFocusColumn;
        if (!primaryKey || dataIndex === primaryKey) {
            return;
        }
        // 等待数据变更完成后，再判断
        setTimeout(() => {
            const primaryValue = this.props.dataResource[rowIndex][primaryKey];
            if (!primaryValue) {
                this.$refs.primaryColumns[rowIndex].$childFocus();
            }
        }, 20);
    }

    // 聚焦下个表单元素
    _focusNextItem(nextItem, actType) {
        // 回车调度时
        if (actType === 'enter') {
            // 如果【自动下一个聚焦】被关闭，或没有设置允许的项，不允许调度
            if (!this.props.autoNextFoucs || !this._data.autoNextFoucs.length) {
                return;
            }
            // 仅聚焦允许的项，不允许时，递归下一项
            const allowDataIndex = this._data.autoNextFoucs;
            if (allowDataIndex.indexOf(nextItem.dataIndex) < 0) {
                this._handleNextFocusByEnter([nextItem.rowKey, nextItem.dataIndex]);
                return;
            }
        }
        // 表单项聚焦
        nextItem.elm.$childFocus(state => {
            // 不能聚焦，下一个元素
            if (!state) {
                // 回车键调度
                if (actType === 'enter') {
                    this._handleNextFocusByEnter([nextItem.rowKey, nextItem.dataIndex]);
                }
                // 方向键调度
                else {
                    this._handleNextFocusByArrow(actType, [
                        nextItem.rowKey,
                        nextItem.dataIndex,
                    ]);
                }
            }
            // 允许聚焦
            else {
                // 如果焦点已换行，通知表格选中新行
                if (nextItem.rowIndex !== this.props.selectedRowIndex) {
                    this.props.onSelectRow({
                        index: nextItem.rowIndex,
                        record: this.props.dataResource[nextItem.rowIndex],
                    });
                }
            }
        });
    }

    // 下一个聚焦（方向键调度）
    _handleNextFocusByArrow(arrow, cell) {
        const focusCell = cell || this.props.focusCell;
        let rowIndex = this.props.dataResource.findIndex(row => row.key === focusCell[0]);
        if (rowIndex < 0) {
            rowIndex = this.props.selectedRowIndex;
        }
        // 如果不能定位当前行，跳过调度
        if (rowIndex < 0) {
            return;
        }
        const formItemsArr = this.$getFormItemsArray();
        const rowItems = formItemsArr[rowIndex];
        // 表单项行内序数（非表格行内序数，不含非表单项）
        const formColIndex = rowItems.findIndex(item => item.dataIndex === focusCell[1]);
        // 向左移动
        if (arrow === 'left') {
            // 如果是第一个，跳过调度
            if (formColIndex === 0) {
                return;
            }
            // 不是第一个，向左移动一位
            else {
                const nextItem = formItemsArr[rowIndex][formColIndex - 1];
                this._focusNextItem(nextItem, arrow);
            }
        }
        // 向右移动
        else if (arrow === 'right') {
            // 如果是最后一个，跳过调度
            if (formColIndex === rowItems.length - 1) {
                return;
            }
            // 否则右移动一位
            else {
                const nextItem = formItemsArr[rowIndex][formColIndex + 1];
                this._focusNextItem(nextItem, arrow);
            }
        }
        // 向上移动
        else if (arrow === 'up') {
            // 如果是第一行，跳过调度
            if (rowIndex === 0) {
                return;
            }
            // 否则上移一行
            else {
                const nextItem = formItemsArr[rowIndex - 1][formColIndex];
                this._focusNextItem(nextItem, arrow);
            }
        }
        // 向下移动
        else if (arrow === 'down') {
            // 如果是最后一行，跳过调度
            if (rowIndex === formItemsArr.length - 1) {
                return;
            }
            // 否则下移一行
            else {
                const nextItem = formItemsArr[rowIndex + 1][formColIndex];
                this._focusNextItem(nextItem, arrow);
            }
        }
    }

    // 下一个聚焦（回车键调度）
    _handleNextFocusByEnter(cell) {
        const focusCell = cell || this.props.focusCell;
        const rowIndex = this.props.dataResource.findIndex(
            row => row.key === focusCell[0],
        );
        // 当前焦点行正常
        if (rowIndex >= 0) {
            const formItemsArr = this.$getFormItemsArray();
            const rowItems = formItemsArr[rowIndex];
            // 表单项行内序数（非表格行内序数，不含非表单项）
            const formColIndex = rowItems.findIndex(
                item => item.dataIndex === focusCell[1],
            );
            // 如果是最后一个，聚焦下一行
            if (formColIndex === rowItems.length - 1) {
                // 如果没有下一行，创建新行
                if (rowIndex === formItemsArr.length - 1) {
                    // 先让当前表单项跑完，再添加新行，修复加值加行同时操作时，值丢失的问题
                    setTimeout(() => {
                        this._addNewRow(rowIndex, addState => {
                            // 如果在当前行是最后一行，且此行是空行时，禁止后续调度，防止无限循环
                            if (addState.isSameRow && addState.isLastRowEmpty) {
                                return;
                            }
                            // 创建完后，重新调度
                            this._handleNextFocusByEnter(cell);
                        });
                    }, 20);
                }
                // 如果已有，聚焦下一行
                else {
                    const nextItem = formItemsArr[rowIndex + 1][0];
                    this._focusNextItem(nextItem, 'enter');
                }
            }
            // 如果不是最后一个，聚焦下一个
            else {
                const nextItem = formItemsArr[rowIndex][formColIndex + 1];
                this._focusNextItem(nextItem, 'enter');
                // 如果下一个是最后一行的最后一个，创建新行
                if (
                    rowIndex === formItemsArr.length - 1 &&
                    formColIndex + 1 === rowItems.length - 1
                ) {
                    this._addNewRow(rowIndex);
                }
            }
        }
        // 当前焦点行已不存在
        else {
            // 没有选中行，跳过
            if (this.props.selectedRowIndex < 0) {
                return;
            }
            const formItemsArr = this.$getFormItemsArray();
            // 按选中行第一个元素聚焦
            const nextItem = formItemsArr[this.props.selectedRowIndex][0];
            this._focusNextItem(nextItem, 'enter');
        }
    }

    _addNewRow(curRowIndex, callback) {
        const lastDataRow = this.props.dataResource[this.props.dataResource.length - 1];
        const { key, ...resetRow } = lastDataRow;
        const isLastRowEmpty = Tools.isEmptyObject(resetRow, 'checkValue');
        const isSameRow = curRowIndex === this.props.dataResource.length - 1;
        // 如果最后一行已经是无内容的空行，不允许再继续建新行
        if (!isLastRowEmpty) {
            this.props.onAddNewRow();
        }
        setTimeout(() => {
            callback && callback({ isLastRowEmpty, isAdded: !isLastRowEmpty, isSameRow });
        }, 20);
    }

    // 方向键移动焦点
    _handleArrowKey(evt) {
        // 非方向键跳过
        if ([37, 38, 39, 40].indexOf(evt.keyCode) < 0) {
            return;
        }
        // 部分表单项跳过
        if (evt.nativeEvent.tableFormArrowIgnore) {
            return;
        }
        // 阻止默认行为，修复方向键调度时，文本框不选中文本的问题
        evt.preventDefault();
        evt.nativeEvent.preventDefault();
        // 方向键焦点调度
        switch (evt.keyCode) {
            // 向左（←）
            case 37:
                this._handleNextFocusByArrow('left');
                break;
            // 向上（↑）
            case 38:
                this._handleNextFocusByArrow('up');
                break;
            // 向右（→）
            case 39:
                this._handleNextFocusByArrow('right');
                break;
            // 向下（↓）
            case 40:
                this._handleNextFocusByArrow('down');
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div
                className="c-table-form-arrangment"
                ref={elm => (this.$refs.arrangment = elm)}
                onKeyDown={evt => this._handleArrowKey(evt)}
            >
                {this.props.children}
            </div>
        );
    }

    $renderColumn({ column, rowIndex, colIndex, text, record }) {
        return (
            <CTableFormItem
                colTitle={column.title}
                rowIndex={rowIndex}
                dataIndex={column.dataIndex}
                record={record}
                rules={column.rules}
                focusCell={this.props.focusCell}
                ref={elm => {
                    if (!column.isFormItem) {
                        return;
                    }
                    // 表单项方阵
                    if (!this.$refs.formItems[rowIndex]) {
                        this.$refs.formItems[rowIndex] = {};
                    }
                    this.$refs.formItems[rowIndex][column.dataIndex] = {
                        elm,
                        rowIndex,
                        rowKey: record.key,
                        colIndex,
                        dataIndex: column.dataIndex,
                    };
                    // 需要聚焦的主列
                    if (
                        this.props.primaryFocusColumn &&
                        column.dataIndex === this.props.primaryFocusColumn
                    ) {
                        this.$refs.primaryColumns[rowIndex] = elm;
                    }
                }}
                onChildFocus={evt => this._handlePrimaryFocus(evt)}
                onGoNextFocus={evt => this._handleNextFocusByEnter(evt)}
            >
                {column.render(text, record, rowIndex)}
            </CTableFormItem>
        );
    }

    // 获取表单项按序引用的数组
    $getFormItemsArray() {
        const formItemsArr = [];
        for (let i = this.$refs.formItems.length - 1; i >= 0; i--) {
            const formRow = this.$refs.formItems[i];
            let isInvalid = true;
            Object.keys(formRow).forEach(itemKey => {
                if (formRow[itemKey].elm) {
                    isInvalid = false;
                }
            });
            // 清除Ref失效的行
            if (isInvalid) {
                this.$refs.formItems.splice(i, 1);
            }
            // 重新引用
            else {
                const rowArr = [];
                Object.keys(formRow).forEach(key => {
                    rowArr.push(formRow[key]);
                });
                rowArr.sort((a, b) => (a.colIndex < b.colIndex ? -1 : 1));
                formItemsArr.unshift(rowArr);
            }
        }
        return formItemsArr;
    }

    // 设置首次聚焦
    $setFirstFocus() {
        // 如果有主列，聚焦主列
        if (this.props.primaryFocusColumn) {
            this.$refs.primaryColumns[0].$childFocus();
        }
        // 如果没有主列，聚焦第一表单列
        else {
            const formItemsArr = this.$getFormItemsArray();
            const nextItem = formItemsArr[0][0];
            this._focusNextItem(nextItem, 'enter');
        }
    }

    // 设置单元格聚焦
    $setCellFocus(focusCell, actType = 'enter') {
        const rowIndex = this.props.dataResource.findIndex(
            row => row.key === focusCell[0],
        );
        if (rowIndex < 0) {
            return;
        }
        const formItemsArr = this.$getFormItemsArray();
        const rowItems = formItemsArr[rowIndex];
        const formColIndex = rowItems.findIndex(item => item.dataIndex === focusCell[1]);
        const nextItem = formItemsArr[rowIndex][formColIndex];
        this._focusNextItem(nextItem, actType);
    }
}

/**
 * CToolFullExport
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, message, Modal, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CIconBackExport } from '@components/fragments/icons/CIconBackExport';
import { CIconDetailExport } from '@components/fragments/icons/CIconDetailExport';

export class CToolFullExport extends React.Component {
    static propTypes = {
        // 按钮显示标题
        title: PropTypes.string,
        // 操作权限
        permission: PropTypes.bool,
        // 时间限制开启
        timeLimited: PropTypes.bool,
        // 获取导出地址
        getExportUrl: PropTypes.func,
    };

    static defaultProps = {
        title: '',
        permission: true,
        timeLimited: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _checkTimeAllow() {
        const hour = moment().hour();
        let allow = false;
        if (hour < 8) {
            allow = true;
        } else if (13 <= hour) {
            allow = true;
        }
        if (!allow) {
            Modal.warning({
                title: '导出失败！',
                content: (
                    <span>
                        请在系统允许的导出时间段内进行此操作！
                        <br />
                        允许时间为：
                        <br />
                        每天 <b>13:00 ~ 次日07:59</b>
                    </span>
                ),
            });
        }
        return allow;
    }

    _hanldeExport() {
        if (!this.props.permission) {
            message.error('您没有导出此数据的权限！');
            return;
        }
        if (this.props.timeLimited && !this._checkTimeAllow()) {
            return;
        }
        this.setState({ loading: true });
        this.props.getExportUrl(url => {
            this.setState({ loading: false });
            if (!url || this.props.type === 'customExport') {
                return;
            }
            window.open(url);
        });
    }

    render() {
        const title =
            this.props.type === 'fullExport'
                ? '全部导出 (后端)'
                : this.props.title || '全部导出';
        const icon =
            this.props.type === 'fullExport' ? (
                <CIconBackExport className="c-table-full-export" />
            ) : (
                <CIconDetailExport className="c-table-merge-export" />
            );
        return (
            <Tooltip title={this.state.loading ? '导出中...' : title} mouseEnterDelay={0}>
                <div>
                    <Button
                        size="small"
                        style={{ display: this.state.loading ? 'none' : '' }}
                        onClick={evt => this._hanldeExport()}
                    >
                        {icon}
                    </Button>
                    <Button
                        size="small"
                        style={{ display: this.state.loading ? '' : 'none' }}
                    >
                        <LoadingOutlined spin />
                    </Button>
                </div>
            </Tooltip>
        );
    }
}

/**
 * CRichTable
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip, Pagination } from 'antd';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CResizeableHead } from './CResizeableHead';
import { CExpandableEllipsis } from './CExpandableEllipsis';
import { CTableSummary } from './CTableSummary';
import { CToolFilter } from './CToolFilter';
import { CToolCurExport } from './CToolCurExport';
import { CToolFullExport } from './CToolFullExport';
import { CToolMergeExport } from './CToolMergeExport';
import { CToolDetailExport } from './CToolDetailExport';
import './cRichTable.scss';

export class CRichTable extends React.Component {
    static propTypes = {
        // 滚动高度
        scrollHeight: PropTypes.number,
        // 自动匹配最大高度
        autoMaxHeigh: PropTypes.bool,
        // 自动高度时，需要减去的额外高度
        trimHeight: PropTypes.number,
        // 表格加载状态
        tableLoading: PropTypes.bool,
        // 表格顶部标题
        title: PropTypes.func,
        // 设置表格工具
        tools: PropTypes.arrayOf(PropTypes.object),
        // 分页设置
        pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        // 解开一页条数限制（每页10条而实际有30条数据时，会将10条后的数据截掉）
        unlockPageSize: PropTypes.bool,
        // 默认列设置
        defaultColumns: PropTypes.arrayOf(PropTypes.object),
        // 翻页排序操作回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        scrollHeight: 400,
        autoMaxHeigh: true,
        trimHeight: 0,
        unlockPageSize: false,
        tools: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            columns: this.props.defaultColumns,
            scrollHeight: this.props.scrollHeight,
            tableWidth: 0,
            mobScreen: window.innerWidth < 576,
        };
        this.$refs = {};
        this._listeners = {};
        this._data = {
            filters: {},
            sorter: {},
        };
    }

    componentDidMount() {
        const hasFilter = this.props.tools.find(item => item.type === 'filter');
        if (!hasFilter) {
            // 初始化列
            this._createColumns();
            // 开启 filter 时，初始化列由 filter 进行
        }
        this._listeners.onResize = () => {
            if (!this.props.autoMaxHeigh) {
                return;
            }
            if (!this.$refs.container) {
                return;
            }
            // 按需填充，填充到满屏为止
            const containerTop = this.$refs.container.getBoundingClientRect().y;
            // 表格顶部高度
            let titleH = 0;
            if (this.props.title || this.props.tools.length > 0) {
                const $title = this.$refs.container.querySelector('.ant-table-title');
                titleH = $title.getBoundingClientRect().height;
            }
            // 标题高度
            let headH = 48;
            if (this.state.columns.find(col => col.children && col.children.length > 0)) {
                headH = 94;
            }
            // 页码高度
            const paginationH = this.props.pagination ? 57 : 0;
            // 底部距离：内容区内边距 + 窗口区内边距
            const endSideH = 15 + 10;
            // 填充高度
            const fillHeight =
                window.innerHeight -
                containerTop -
                titleH -
                headH -
                paginationH -
                endSideH -
                this.props.trimHeight;
            const minimumHeight = window.innerWidth >= 576 ? 300 : 355;
            this.setState({
                // 保留表格最小高度
                scrollHeight: Math.max(fillHeight, minimumHeight),
                // 是否为移动平台
                mobScreen: window.innerWidth < 576,
            });
        };
        setTimeout(this._listeners.onResize, 0);
        window.addEventListener('resize', this._listeners.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._listeners.onResize);
    }

    // 处理拖拽调整列大小的问题
    _handleResize(index) {
        return (evt, resizeData) => {
            const nextColumns = [...this.state.columns];
            if (resizeData.width === nextColumns[index].width) {
                return;
            }
            const nextChildren = nextColumns[index].children;
            // 单列
            if (!nextChildren || nextChildren.length === 0) {
                nextColumns[index] = {
                    ...nextColumns[index],
                    width: resizeData.width || nextColumns[index].width,
                };
            }
            // 包含子列
            else {
                const parentWidth = resizeData.width || nextColumns[index].width;
                nextChildren.forEach(child => {
                    child.width = parseInt(parentWidth * child.widthRatio);
                });
                nextColumns[index] = {
                    ...nextColumns[index],
                    width: parentWidth,
                    children: nextChildren,
                };
            }
            this.setState({
                columns: nextColumns,
            });
        };
    }

    // 处理设定所有列宽度下，固定显示的列的悬浮层，宽度不够的问题
    //  当表格被“拉宽”时，取消固定列的悬浮层显示
    _handleFixed(index) {
        return (evt, fixed) => {
            const nextColumns = [...this.state.columns];
            nextColumns[index] = {
                ...nextColumns[index],
                fixed,
            };
            this.setState({
                columns: nextColumns,
            });
        };
    }

    // 创建列数据排序
    _createColumnSorter(column) {
        if (column.sorter) {
            column.sortDirections = ['descend', 'ascend'];
        }
        // 当关闭翻页时，开启前端排序
        if (!this.props.pagination) {
            if (column.sorter && column.dataIndex) {
                column.sorter = (a, b) => {
                    // 数值
                    if (typeof a[column.dataIndex] === 'number') {
                        return a[column.dataIndex] - b[column.dataIndex];
                    }
                    // 可转数值字符串
                    else if (!isNaN(a[column.dataIndex])) {
                        return Number(a[column.dataIndex]) - Number(b[column.dataIndex]);
                    }
                    // 字符串
                    else if (typeof a[column.dataIndex] === 'string') {
                        return a[column.dataIndex] < b[column.dataIndex] ? -1 : 1;
                    }
                };
            }
        }
    }

    // 重设单列的列配置
    _createColumnBySingle(column, index) {
        const that = this;
        // 列标题宽度 = 转换后的字符个数 * 7 + 单元格内边距 + 排序图标宽度
        let headerWidth =
            (String(column.title) || '').replace(/[\u4e00-\u9fa5]/g, 'aa').length * 7 +
            16;
        headerWidth += typeof column.sorter === 'undefined' || column.sorter ? 20 : 0;
        headerWidth += typeof column.helper === 'undefined' || column.helper ? 20 : 0;
        // 重设列配置
        const column2 = {
            width: Math.max(100, headerWidth),
            align: 'center', // 默认居中
            fixed: false, // 默认不开启固定
            sorter: false, // 默认关闭排序
            ellipsis: true, // 默认开启省略
            resizeable: true, // 默认开启缩放
            ...column,
            title: this._renderColTitleHelper(column),
            key: 'col-' + index,
            onHeaderCell: curColumn => ({
                colIndex: index,
                width: curColumn.width,
                fixed: curColumn.fixed,
                resizeable: curColumn.resizeable,
                onResize: that._handleResize(index),
                onFixedChange: that._handleFixed(index),
            }),
        };
        this._createColumnSorter(column2);
        // 移动端关闭固定
        if (this.state.mobScreen) {
            column2.fixed = false;
        }
        return column2;
    }

    // 重设带子级的列配置
    _createColumnByChildren(column, index) {
        const that = this;
        // 父级列标题宽度 = 转换后的字符个数 * 7 + 单元格内边距
        const parentWidth =
            (String(column.title) || '').replace(/[\u4e00-\u9fa5]/g, 'aa').length * 7 +
            16;
        // 子级列标题总宽度
        let childrenWidth = 0;
        const children = column.children
            .map((child, index2) => {
                if (!child) {
                    return null;
                }
                // 子级标题宽度 = 转换后的字符个数 * 7 + 单元格内边距 + 排序图标宽度
                let childWidth =
                    child.title.replace(/[\u4e00-\u9fa5]/g, 'aa').length * 7 + 20;
                childWidth +=
                    typeof child.sorter === 'undefined' || child.sorter ? 20 : 0;
                childWidth +=
                    typeof child.helper === 'undefined' || child.helper ? 20 : 0;
                const child2 = {
                    width: Math.max(child.width || 0, childWidth),
                    align: 'center', // 默认居中
                    ellipsis: true, // 默认开启省略
                    sorter: false, // 默认关闭排序
                    ...child,
                    fixed: false, // 子级强制不允许固定
                    resizeable: false, // 子级强制不开启缩放
                    title: this._renderColTitleHelper(child),
                    key: 'col-' + index + '-child-' + index2,
                };
                this._createColumnSorter(child2);
                // 子级宽度累加
                childrenWidth += child2.width;
                return child2;
            })
            .filter(Boolean);
        children.forEach(child2 => {
            child2.widthRatio = child2.width / childrenWidth;
        });
        // 重设列配置
        const column2 = {
            width: Math.max(parentWidth, childrenWidth),
            align: 'center', // 默认居中
            fixed: false, // 默认不开启固定
            ellipsis: true, // 默认开启省略
            resizeable: true, // 默认允许缩放
            ...column,
            sorter: false, // 父级强制不允许排序
            title: this._renderColTitleHelper(column),
            key: 'col-' + index,
            children,
            onHeaderCell: curColumn => ({
                colIndex: index,
                width: curColumn.width,
                fixed: curColumn.fixed,
                resizeable: curColumn.resizeable,
                onResize: that._handleResize(index),
                onFixedChange: that._handleFixed(index),
            }),
        };
        // 移动端关闭固定
        if (this.state.mobScreen) {
            column2.fixed = false;
        }
        return column2;
    }

    _createColumns(filterColumns) {
        let tableWidth = 0;
        const columns = (filterColumns || this.props.defaultColumns)
            .map((column, index) => {
                let column2 = null;
                // 普通列（没有子级）
                if (!column.children || column.children.length === 0) {
                    column2 = this._createColumnBySingle(column, index);
                }
                // 存在子级的列
                else {
                    column2 = this._createColumnByChildren(column, index);
                }
                // 表格总宽，用于水平滚动
                tableWidth += column2 ? column2.width : 0;
                return column2;
            })
            .filter(Boolean);
        this.setState({
            columns,
            tableWidth,
        });
    }

    // 显示帮助信息
    _renderColTitleHelper(col) {
        if (!col.helper) {
            return col.title;
        } else {
            return (
                <span className="c-table-title-helper">
                    <Tooltip placement="top" title={col.helper}>
                        {col.title}
                        <QuestionCircleOutlined />
                    </Tooltip>
                </span>
            );
        }
    }

    _renderTableTools() {
        if (!this.props.tools || this.props.tools.length === 0) {
            return null;
        }
        return this.props.tools.map((tool, index) => {
            switch (tool.type) {
                case 'filter':
                    return (
                        <CToolFilter
                            key={tool.type + '-' + index}
                            tableName={tool.name}
                            columns={this.props.defaultColumns}
                            onChange={evt => {
                                this._createColumns(evt);
                            }}
                        />
                    );
                case 'curExport':
                    return (
                        <CToolCurExport
                            key={tool.type + '-' + index}
                            fileName={tool.fileName}
                            columns={this.props.defaultColumns}
                            extraColumns={tool.extraColumns}
                            dataSource={this.props.dataSource}
                            pageIndex={
                                this.props.pagination ? this.props.pagination.current : 1
                            }
                            permission={tool.permission}
                        />
                    );
                case 'mergeExport':
                    return (
                        <CToolMergeExport
                            key={tool.type + '-' + index}
                            fileName={tool.fileName}
                            pageSize={tool.pageSize}
                            total={this.props.pagination.total}
                            columns={this.props.defaultColumns}
                            extraColumns={tool.extraColumns}
                            permission={tool.permission}
                            timeLimited={true}
                            onExport={evt => this.props.onMergeExport(evt)}
                        />
                    );
                case 'detailExport':
                    return (
                        <CToolDetailExport
                            key={tool.type + '-' + index}
                            fileName={tool.fileName}
                            columns={tool.columns}
                            total={this.props.pagination.total}
                            permission={tool.permission}
                            timeLimited={true}
                            onExport={evt => this.props.onDetailExport(evt)}
                        />
                    );
                case 'fullExport':
                    return (
                        <CToolFullExport
                            key={tool.type + '-' + index}
                            type={tool.type}
                            permission={tool.permission}
                            timeLimited={true}
                            getExportUrl={evt => tool.getExportUrl(evt)}
                        />
                    );
                case 'customExport':
                    return (
                        <CToolFullExport
                            key={tool.type + '-' + index}
                            type={tool.type}
                            title={tool.title}
                            permission={tool.permission}
                            timeLimited={true}
                            getExportUrl={evt => tool.getExportUrl(evt)}
                        />
                    );
                default:
                    return null;
            }
        });
    }

    _renderTableTitle() {
        let titleContent = null;
        if (this.props.title) {
            try {
                titleContent = this.props.title();
            } catch (e) {}
        }
        // 没有设置 title 和 tools
        if (!titleContent && this.props.tools.length === 0) {
            return null;
        }
        // 设置了表格标题或工具
        else {
            return current => (
                <div className="c-table-title">
                    <div className="c-table-title-content">
                        {titleContent && this.props.title(current)}
                    </div>
                    <div className="c-table-title-tools">{this._renderTableTools()}</div>
                </div>
            );
        }
    }

    _renderTableSummary(datas) {
        return (
            <CTableSummary
                defaultColumns={this.props.defaultColumns}
                columns={this.state.columns}
                datas={datas}
                hasSelection={!!this.props.rowSelection}
            />
        );
    }

    render() {
        const { defaultColumns, autoMaxHeigh, pagination, title, tools, ...resetProps } =
            this.props;
        const components = {
            header: { cell: CResizeableHead },
            body: { cell: CExpandableEllipsis },
        };
        const tableLoading = {
            indicator: <LoadingOutlined spin />,
            spinning: this.props.tableLoading,
        };
        const tableScroll = {
            x: this.state.tableWidth, // 表格计算宽度
            y: this.state.scrollHeight, // 最大填充至满屏
        };
        const pagination2 = pagination && {
            showTotal: total => `共 ${total} 条`,
            ...pagination,
        };
        return (
            <div className="c-rich-table" ref={elm => (this.$refs.container = elm)}>
                <Table
                    {...resetProps}
                    columns={this.state.columns}
                    tableWidth={this.state.tableWidth}
                    rowKey={row => row.id || row.key}
                    size="middle"
                    bordered
                    title={this._renderTableTitle()}
                    summary={evt => this._renderTableSummary(evt)}
                    showSorterTooltip={false}
                    components={components}
                    loading={tableLoading}
                    pagination={!this.props.unlockPageSize && pagination2}
                    scroll={tableScroll}
                    onChange={(pagination, filters, sorter, extra) => {
                        this._data.filters = filters;
                        this._data.sorter = sorter;
                        // 只有允许翻页时，所有操作才走后端
                        if (this.props.pagination) {
                            this.props.onChange(pagination, filters, sorter);
                        }
                    }}
                />
                {this.props.unlockPageSize ? (
                    <Pagination
                        size="small"
                        style={{ margin: '16px 0', textAlign: 'right' }}
                        {...pagination2}
                        onChange={(page, pageSize) =>
                            this.props.onChange(
                                { current: page, pageSize },
                                this._data.filters,
                                this._data.sorter,
                            )
                        }
                    />
                ) : null}
            </div>
        );
    }

    static $createRowLid(span, children) {
        return {
            props: {
                className: 'c-rich-table-row-lid',
            },
            children: (
                <div
                    className="c-rich-table-row-lid-inner"
                    style={{
                        height: 'calc(' + span + '00% + ' + (span - 1) + 'px)',
                    }}
                >
                    <div className="c-rich-table-row-lid-content">{children}</div>
                </div>
            ),
        };
    }
}

/**
 * CToolMergeExport
 * @author Tevin
 * @tutorial
 *   默认请求频率：0.55(平均请求时间) + 0.1(请求间隔) + 0.05(页面处理延迟) = 0.7 秒/次
 *   限速请求频率：0.55(平均请求时间) + 2.5(请求间隔) + 0.05(页面处理延迟) = 3.0 秒/次
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { BXlsxExport } from '@components/plugins/richTable/bases/BXlsxExport';
import { Button, Modal, Tooltip, Progress, message } from 'antd';
import { ExportOutlined, LoadingOutlined, CheckOutlined } from '@ant-design/icons';
import { CItemEditor } from '@components/plugins/itemEditor/CItemEditor';
import { CText } from '@components/fragments/text/CText';
import './cToolMergeExport.scss';

export class CToolMergeExport extends BXlsxExport {
    static propTypes = {
        // 图标悬浮提示
        tipTitle: PropTypes.string,
        // 占位图标
        icon: PropTypes.node,
        // 导出文件名
        fileName: PropTypes.string,
        // 导出每页大小
        pageSize: PropTypes.number,
        // 列设置
        columns: PropTypes.array,
        // 扩展列
        extraColumns: PropTypes.array,
        // 操作权限
        permission: PropTypes.bool,
        // 时间限制开启
        timeLimited: PropTypes.bool,
        // 发起导出回调
        onExport: PropTypes.func,
    };

    static defaultProps = {
        tipTitle: '导出全部页面',
        fileName: '表格',
        columns: [],
        extraColumns: [],
        pageSize: 500,
        permission: true,
        timeLimited: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            exportLoading: false,
            exportFinish: false,
            dataResource: [],
            pagination: {
                current: 0,
                pageSize: this.props.pageSize,
                total: 0,
            },
            loadPercent: 0,
        };
        this._data = {
            // 平均请求时间
            avgReqTime: 550,
            lastReqTimes: [550],
            // 基础加载间隔
            loadGap: 0,
            loadGapType: [100, 2500],
        };
        this.$refs = {};
        this._export.mode = 'merge';
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _checkTimeLimitMode() {
        const hour = moment().hour();
        let allow = false;
        if (hour < 8) {
            allow = true;
        } else if (13 <= hour) {
            allow = true;
        }
        this._data.loadGap = this._data.loadGapType[allow ? 0 : 1];
        return allow;
    }

    _beforeExport() {
        if (this.state.exportLoading) {
            return;
        }
        // 初始化总数据条数
        this.setState({
            exportLoading: true,
            timeAllow: !this.props.timeLimited || this._checkTimeLimitMode(),
            pagination: {
                ...this.state.pagination,
                current: 0,
                total: this.props.total || 1,
            },
        });
        // 动画
        setTimeout(() => {
            this.setState({
                innerShow: true,
            });
        }, 100);
    }

    _getExportPage(pageCallback, recursion = 0) {
        const nextPage = this.state.pagination.current + 1;
        const totalPage = Math.ceil(
            parseInt(this.state.pagination.total) / this.state.pagination.pageSize,
        );
        // 超过页码，终止
        if (nextPage > totalPage) {
            return pageCallback('end');
        }
        // 获取数据
        const reqStart = Date.now();
        this.props.onExport({
            pagination: {
                ...this.state.pagination,
                current: nextPage,
            },
            callback: res => {
                this._data.lastReqTimes.push(Date.now() - reqStart);
                // 请求失败时
                if (!res) {
                    // 重复三次后弹窗询问
                    if (recursion > 3) {
                        Modal.confirm({
                            title: '网络故障！',
                            content: '网络出现故障，是否继续导出？',
                            okText: '继续导出',
                            onOk: () => {
                                this._getExportPage(pageCallback);
                            },
                            onCancel: () => {
                                setTimeout(() => {
                                    this._handleClose();
                                }, 100);
                            },
                        });
                    } else {
                        setTimeout(() => {
                            this._getExportPage(pageCallback, ++recursion);
                        }, 200);
                    }
                    return;
                }
                // 请求成功
                else {
                    // 空数据判断
                    if (
                        res.total === 0 ||
                        (nextPage === 1 && res.dataResource.length === 0)
                    ) {
                        Modal.warning({
                            title: '无数据',
                            content: (
                                <div>
                                    当前查询条件下，暂无数据可以导出！
                                    <br />
                                    <CText type="ignore">（请更改查询条件后再试）</CText>
                                </div>
                            ),
                            onOk: () => {
                                setTimeout(() => {
                                    this._handleClose();
                                }, 100);
                            },
                        });
                        return;
                    }
                    // 数据正常
                    this.setState({
                        dataResource: res.dataResource,
                        pagination: {
                            ...this.state.pagination,
                            current: nextPage,
                            total: res.total,
                        },
                    });
                    if (this._checkRowsLimit(res.total) === 'stop') {
                        pageCallback(-1);
                    } else {
                        pageCallback(nextPage - 1);
                    }
                }
            },
            stopper: () => {
                setTimeout(() => {
                    this._handleClose();
                }, 270);
            },
        });
        this._updateAvgTime();
    }

    // 更新平均请求时间
    _updateAvgTime() {
        while (this._data.lastReqTimes.length > 5) {
            this._data.lastReqTimes.shift();
        }
        let total = 0;
        this._data.lastReqTimes.forEach(time => (total += time));
        this._data.avgReqTime = parseInt(total / this._data.lastReqTimes.length);
    }

    _transProcess(step) {
        const total = parseInt(this.state.pagination.total || this.props.total);
        // 总页数
        const pageTotal = Math.ceil(total / this.state.pagination.pageSize);
        step = step === 'end' ? pageTotal : step;
        // 进度百分比
        const percent = ((step / pageTotal) * 100).toFixed(2);
        // 剩余时间
        const surplus =
            (pageTotal - step) * (this._data.loadGap + 50 + this._data.avgReqTime);
        const duration = moment.duration(surplus || 0);
        const long = {
            hour: parseInt(duration.asHours()),
            minutes: duration.minutes(),
            seconds: duration.seconds(),
        };
        long.seconds = Math.max(long.seconds, 0);
        let timeWord = '大约';
        if (long.hour > 0) {
            timeWord += ' ' + long.hour + ' 小时 ' + long.minutes + ' 分';
        } else if (long.minutes > 0) {
            timeWord += ' ' + long.minutes + ' 分 ' + long.seconds + ' 秒';
        } else {
            timeWord += ' ' + long.seconds + ' 秒';
        }
        // 显示
        this.setState({
            loadPercent: Number(percent),
            timeSurplus: timeWord,
        });
    }

    _checkRowsLimit(total) {
        // 总数超过20万条，阻止导出
        if (total > 20 * 10000) {
            Modal.confirm({
                title: '导出失败！',
                content: '您选择的导出数据过多，请筛选后再导出！',
                okText: '知道了',
                okType: 'primary',
                onOk: closer => {
                    closer();
                    setTimeout(() => {
                        this._handleClose();
                    }, 100);
                },
            });
            return 'stop';
        }
        return 'pass';
    }

    _hanldeExport() {
        if (!this.props.permission) {
            message.error('您没有导出此数据的权限！');
            return;
        }
        if (this._checkRowsLimit(this.props.total) === 'stop') {
            return;
        }
        this._beforeExport();
        const getPageData = () => {
            // 延迟一个间隔再加载
            const loadGap = this.state.pagination.current === 0 ? 0 : this._data.loadGap;
            setTimeout(() => {
                if (!this.state.exportLoading) {
                    return;
                }
                this._getExportPage(step => {
                    // 加载过程
                    if (step >= 0) {
                        this._transProcess(step);
                        setTimeout(() => {
                            if (!this.state.exportLoading) {
                                return;
                            }
                            this.graspRowDatasFromTable();
                            // 下一页
                            getPageData();
                        }, 50);
                    }
                    // 加载终止
                    else if (step < 0) {
                        this._transProcess(0);
                    }
                    // 加载完成
                    else if (step === 'end') {
                        this._transProcess(step);
                        this.setState({
                            exportFinish: true,
                        });
                        // 完成时关闭询问窗口
                        this.$refs.confirmClose && this.$refs.confirmClose.destroy();
                    }
                });
            }, loadGap);
        };
        this._transProcess(0);
        getPageData();
    }

    _handleClose() {
        this.clearRowDatas();
        // 动画
        this.setState({
            innerShow: false,
        });
        setTimeout(() => {
            this.setState({
                exportLoading: false,
                exportFinish: false,
                timeAllow: false,
                pagination: {
                    ...this.state.pagination,
                    current: 0,
                    total: this.props.total,
                },
            });
        }, 200);
    }

    _renderExplanation() {
        return (
            <div>
                <p>
                    为了避免导出操作对业务运行产生冲击，影响业务的稳定性，在
                    <b>高峰时段将对导出的速度进行限制</b>！
                </p>
                <br />
                <p>
                    高峰时间段指：
                    <br />
                    每天 <b>08:00 ~ 12:59</b>
                </p>
                <br />
                <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={evt => this.$refs.explain.$close()}>
                        了解了
                    </Button>
                </div>
                <br />
            </div>
        );
    }

    _renderConfirmClose() {
        const msgs = [
            ['是否终止导出？', '终止导出将会丢弃已处理进度'],
            ['是否关闭导出？', '关闭后已处理数据将被清空，下次仍需要重新导出'],
        ];
        this.$refs.confirmClose = Modal.confirm({
            title: msgs[this.state.exportFinish ? 1 : 0][0],
            content: msgs[this.state.exportFinish ? 1 : 0][1],
            okText: this.state.exportFinish ? '确认关闭' : '确认终止',
            okType: 'danger',
            onOk: closer => {
                closer();
                setTimeout(() => {
                    this._handleClose();
                }, 100);
            },
        });
    }

    _renderProcess() {
        if (!this.state.exportLoading) {
            return null;
        }
        return (
            <div className="c-merge-export-process">
                <div className={['inner ', this.state.innerShow ? 'on' : ''].join(' ')}>
                    <div className="icon">
                        {this.state.exportFinish ? (
                            <CheckOutlined />
                        ) : (
                            <img src="/static/assets/images/loading-200.gif" alt="" />
                        )}
                    </div>
                    <div className="right">
                        <h2 className="title">
                            <strong>
                                {this.state.exportFinish ? '导出已就绪' : '正在导出'}
                            </strong>
                            <CText
                                className="tips"
                                type="danger"
                                style={{ display: this.state.timeAllow ? 'none' : '' }}
                            >
                                当前正处于
                                <CItemEditor
                                    width={400}
                                    title="关于导出限速"
                                    content={this._renderExplanation()}
                                    iconType="viewer"
                                    ref={elm => (this.$refs.explain = elm)}
                                >
                                    导出限速
                                </CItemEditor>
                                时间段内，将花费较长时间！
                            </CText>
                        </h2>
                        <div className="progress">
                            <Progress percent={this.state.loadPercent} />
                            {this.state.exportFinish ? (
                                <div>请下载文档</div>
                            ) : (
                                <div className="tips">
                                    剩余时间：{this.state.timeSurplus}
                                </div>
                            )}
                        </div>
                        <div className="btn">
                            <Button
                                danger={!this.state.exportFinish}
                                onClick={evt => this._renderConfirmClose()}
                            >
                                {this.state.exportFinish ? '关闭' : '终止'}导出
                            </Button>
                            <Button
                                type="primary"
                                disabled={!this.state.exportFinish}
                                onClick={evt => this.onDownloadXLSX()}
                            >
                                下载文档
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="c-merge-export">
                <Tooltip
                    title={this.props.tipTitle}
                    mouseEnterDelay={0}
                    trigger={['hover', 'click']}
                >
                    <div>
                        <Button
                            size="small"
                            style={{ display: this.state.exportLoading ? 'none' : '' }}
                            onClick={evt => this._hanldeExport()}
                        >
                            {this.props.icon ? (
                                this.props.icon
                            ) : (
                                <ExportOutlined className="c-table-merge-export" />
                            )}
                        </Button>
                        <Button
                            size="small"
                            style={{ display: this.state.exportLoading ? '' : 'none' }}
                        >
                            <LoadingOutlined spin />
                        </Button>
                    </div>
                </Tooltip>
                {this.renderExportTable(this.state.exportLoading)}
                {this._renderProcess()}
            </div>
        );
    }
}

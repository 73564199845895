/**
 * CTableFormItem 表格式表单-表单项
 * @author Tevin
 * @tutorial rules
 *      see https://github.com/yiminghe/async-validator#type
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Input, InputNumber, AutoComplete, Checkbox, Select } from 'antd';
import { CPlainSelect } from '@components/forms/plainSelect/CPlainSelect';
import { CInputCalcable } from '@components/forms/inputCalcable/CInputCalcable';
import { BTableFormContext } from '@components/plugins/tableForm/bases/BTableFormContext';
import { Tools } from '@components/common/Tools';
import Schema from 'async-validator';
import { validateMsgs } from '@components/plugins/tableForm/validateMsgs.js';

/** 已支持全键盘操作的表单项，设定关口名 **/
// Antd组件
Input.gateName = 'Input';
InputNumber.gateName = 'InputNumber';
AutoComplete.gateName = 'AutoComplete';
Checkbox.gateName = 'Checkbox';
Select.gateName = 'Select';
// 公共库组件
CPlainSelect.gateName = 'CPlainSelect';
CInputCalcable.gateName = 'CInputCalcable';

export class CTableFormItem extends React.Component {
    static contextType = BTableFormContext;

    static propTypes = {
        // 当前单元格行数
        rowIndex: PropTypes.number,
        // 当前单元格键名
        dataIndex: PropTypes.string,
        // 列标题
        colTitle: PropTypes.string,
        // 当前整条数据
        record: PropTypes.object,
        // 表单项验证规则
        //   例如：[{type:'string', min: 2, max: 100}]
        //   常用项：
        //     type        string           类型，常见有 string、number、boolean、array、object、url、email
        //     len         number           string 类型时为字符串长度；number 类型时为确定数字； array 类型时为数组长度
        //     max         number           必须设置 type：string 类型为字符串最大长度；number 类型时为最大值；array 类型时为数组最大长度
        //     min         number           必须设置 type：string 类型为字符串最小长度；number 类型时为最小值；array 类型时为数组最小长度
        //     pattern     RegExp           正则表达式匹配
        //     required    boolean          是否为必选字段
        //     transform   (value) => any   将字段值转换成目标值后进行校验
        //     message     string           错误信息，不设置时会通过模板自动生成
        rules: PropTypes.array,
        // 聚焦单元格
        focusCell: PropTypes.array,
        // 子项聚焦回调
        onChildFocus: PropTypes.func,
        // 表单验证回调
        onValidated: PropTypes.func,
        // 发起聚焦下一个回调
        onGoNextFocus: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            errorMsg: '',
        };
        this.$refs = {
            box: null,
            children: null,
        };
        this._data = {
            // 是否挂载
            isMounted: false,
            // 类型菜单
            types: {
                // 上下方向键调度忽略的表单项
                cellArrowIgnore: [
                    'AutoComplete',
                    'Select',
                    'InputNumber',
                    'CPlainSelect',
                ],
                // 聚焦自动补按下方向键的表单项
                autoArrowDown: ['AutoComplete', 'Select'],
                // 自动选中文本内容
                autoSelection: ['Input', 'InputNumber', 'CInputCalcable'],
                // 点击单元格自动聚焦的表单项
                focusByClick: ['Checkbox'],
                // 值为checked的表单项
                valueAsChecked: ['Checkbox'],
                // 通过单元格回车来完成的表单项
                passByCellEnter: ['Checkbox'],
                // 类选择框式完成的表单项
                passLikeSelect: ['AutoComplete', 'Select', 'CPlainSelect'],
                // 类输入框式完成的表单项
                passLikeInput: ['Input', 'InputNumber', 'CInputCalcable'],
            },
        };
        this._listener = {};
        // 表单验证器
        this.$validator = null;
    }

    componentDidMount() {
        this._data.isMounted = true;
        this._createValidator();
        this._checkChildTypeKeyboardSupport();
    }

    componentWillUnmount() {
        this._data.isMounted = false;
        this.$validator = null;
    }

    // 创建验证器
    _createValidator() {
        if (!Tools.isArray(this.props.rules) || this.props.rules.length === 0) {
            this.$validator = null;
            return;
        }
        this.$validator = new Schema({
            [this.props.dataIndex]: this.props.rules,
        });
        // 汉化通用验证消息
        this.$validator.messages(validateMsgs);
    }

    // 重新验证
    _reValidate() {
        // 等待 value 变更后，再验证
        setTimeout(() => {
            // 处于报错状态时，重新验证
            if (this.state.errorMsg) {
                this.$validate();
            }
        }, 20);
    }

    // 设置单元格错误提示
    _setErrorMsg(msg) {
        this.setState({
            errorMsg: msg,
        });
    }

    // 获取直接子元素类型名
    _getChildTypeName() {
        const children = this.props.children;
        // 异常元素
        if (!children || !React.isValidElement(children)) {
            return '';
        }
        // 仅接受已设定关口名的表单项
        return children.type.gateName || '';
    }

    // 检测子项是否可以聚焦
    _checkFocusable() {
        // 异常元素，不可聚焦
        if (!React.isValidElement(this.props.children)) {
            return false;
        }
        // 普通Dom元素，不可聚焦
        if (typeof this.props.children.type === 'string') {
            return false;
        }
        // 是组件，但不支持聚焦，不可聚焦
        if (!this.$refs.children.focus) {
            return false;
        }
        // 已禁用，不可聚焦
        if (this.$refs.children.props.disabled) {
            return false;
        }
        // 可聚焦
        return true;
    }

    // 检查表单项是否已接入全键盘操作支持
    _checkChildTypeKeyboardSupport() {
        // 不可聚焦元素跳过
        if (!this._checkFocusable()) {
            return;
        }
        // 能获取组件关口名的元素跳过
        if (this._getChildTypeName()) {
            return;
        }
        // 无关口名，视为非键盘支持元素，按组件获取
        // （仅开发模式正常，打包后组件名已被混淆压缩）
        let childType = '';
        // 先按组件的申明获取
        if (Tools.isFunction(this.props.children.type)) {
            childType = this.props.children.type.name || '';
        } else if (Tools.isObject(this.props.children.type)) {
            if (Tools.isFunction(this.props.children.type.render)) {
                childType =
                    this.props.children.type.render.name ||
                    this.props.children.type.displayName ||
                    '';
            } else {
                childType = this.props.children.type.displayName || '';
            }
        }
        // 如果申明无法获取，再按实例获取
        if (!childType) {
            // 如果实例已创建，获取实例类名
            if (this.$refs.children) {
                childType = this.$refs.children.constructor.name;
            }
        }
        // 提示不支持
        console.warn(
            'CTableForm警告：表单组件【' +
                childType +
                '】，尚未接入全键盘操作！请在技术群反馈此问题！',
        );
    }

    // 设置子级表单项聚焦
    _setChildFocus() {
        const childType = this._getChildTypeName();
        // 正常聚焦
        this.$refs.children.focus();
        // 自动触发下拉展开
        if (this._data.types.autoArrowDown.indexOf(childType) >= 0) {
            const input = this.$refs.box.querySelector(
                'Input.ant-select-selection-search-input',
            );
            if (!input || !input.dispatchEvent) {
                return;
            }
            input.dispatchEvent(
                new KeyboardEvent('keydown', {
                    key: 'ArrowDown',
                    code: 'ArrowDown',
                    keyCode: 40,
                    which: 40,
                    bubbles: true,
                    shiftKey: false,
                    ctrlKey: false,
                    metaKey: false,
                    // 父级不进行焦点调度
                    tableFormArrowIgnore: true,
                }),
            );
        }
        // 自动选中所有文本
        if (this._data.types.autoSelection.indexOf(childType) >= 0) {
            const input = this.$refs.children.input;
            if (!input || !input.setSelectionRange) {
                return;
            }
            const len = input.value.length * 2;
            input.setSelectionRange(0, len);
        }
    }

    // 单元格操作事件
    _handleBoxOprEvent(type, evt) {
        const childType = this._getChildTypeName();
        if (type === 'keydown') {
            // 对上下方向键事件打标记（父级不进行焦点调度）
            if (this._data.types.cellArrowIgnore.indexOf(childType) >= 0) {
                if (evt.keyCode === 38 || evt.keyCode === 40) {
                    evt.tableFormArrowIgnore = true;
                    evt.nativeEvent.tableFormArrowIgnore = true;
                }
            }
            // 补充回车调度
            if (this._data.types.passByCellEnter.indexOf(childType) >= 0) {
                if (evt.keyCode === 13) {
                    // 等待表格的各子项设置完成后，再聚焦下一项
                    setTimeout(() => {
                        this.props.onGoNextFocus();
                    }, 20);
                }
            }
        } else if (type === 'click') {
            // 点击单元格自动聚焦
            if (
                this._data.types.focusByClick.indexOf(childType) >= 0 ||
                this._data.types.passLikeSelect.indexOf(childType) >= 0
            ) {
                this._setChildFocus();
                // 当表单项有焦点而单元格失去焦点时，补偿一次
                if (!this.props.focusCell[0]) {
                    this.context.setCellFocus({
                        rowKey: this.props.record.key,
                        dataIndex: this.props.dataIndex,
                    });
                }
            }
        }
    }

    // 注入子级通过事件
    _injectChildPassEvent(injection) {
        const childType = this._getChildTypeName();
        // 类选择框绑定通过事件
        if (this._data.types.passLikeSelect.indexOf(childType) >= 0) {
            injection.onSelect = (...args) => {
                if (this.props.children.props.onSelect) {
                    this.props.children.props.onSelect(...args);
                }
                // 等待表格的各子项设置完成后，再聚焦下一项
                setTimeout(() => {
                    this.props.onGoNextFocus();
                }, 20);
            };
        }
        // 类输入框绑定通过事件
        if (this._data.types.passLikeInput.indexOf(childType) >= 0) {
            injection.onPressEnter = (...args) => {
                if (this.props.children.props.onPressEnter) {
                    this.props.children.props.onPressEnter(...args);
                }
                // 等待表格的各子项设置完成后，再聚焦下一项
                setTimeout(() => {
                    this.props.onGoNextFocus();
                }, 20);
            };
        }
    }

    render() {
        const childType = this._getChildTypeName();
        const { children, dataIndex, rowIndex, record } = this.props;
        // 元素注入
        const injection = {
            className:
                'c-table-form-item' +
                (children.props.className ? ' ' + children.props.className : ''),
            // 注入 value
            value: this.context.getItemValue(rowIndex, dataIndex),
            // 注入 onChange
            onChange: (...args) => {
                if (children.props.onChange) {
                    children.props.onChange(...args);
                }
                // 选择类表单元素其值为checked，增加标记
                if (this._data.types.valueAsChecked.indexOf(childType) >= 0) {
                    args[0].target.valueType = 'checked';
                }
                this.context.setItemValue(rowIndex, dataIndex, ...args);
                // 重新验证
                this._reValidate('onChange');
            },
            // 注入 onBlur（辅助表单验证）
            onBlur: (...args) => {
                if (children.props.onFocus) {
                    children.props.onFocus(...args);
                }
                // 重新验证
                this._reValidate('onBlur');
                // 同步单元格样式
                this.context.setCellBlur({ rowKey: record.key, dataIndex });
            },
            // 注入 onFocus（主列聚焦用）
            onFocus: (...args) => {
                if (children.props.onFocus) {
                    children.props.onFocus(...args);
                }
                this.props.onChildFocus({ rowIndex, dataIndex, ...args });
                // 同步单元格样式
                this.context.setCellFocus({ rowKey: record.key, dataIndex });
            },
            // 注入 ref（主列聚焦用）
            ref: elm => {
                if (children.props.ref) {
                    children.props.ref(elm);
                }
                this.$refs.children = elm;
            },
        };
        // 选择类表单元素其值为checked，特殊处理
        if (this._data.types.valueAsChecked.indexOf(childType) >= 0) {
            delete injection.value;
            injection.checked = this.context.getItemValue(rowIndex, dataIndex);
        }
        // 子级表单项通过操作
        this._injectChildPassEvent(injection);
        // 克隆
        const clonedChild = React.cloneElement(children, injection);
        return (
            <Tooltip
                title={this.state.errorMsg}
                color="#ff4d4f"
                overlayStyle={{ marginBottom: -6 }}
                trigger={this.state.errorMsg ? 'hover' : 'none'}
            >
                <div
                    className="c-table-form-item-box"
                    ref={elm => (this.$refs.box = elm)}
                    onKeyDown={evt => this._handleBoxOprEvent('keydown', evt)}
                    onClick={evt => this._handleBoxOprEvent('click', evt)}
                >
                    {clonedChild}
                </div>
            </Tooltip>
        );
    }

    $childFocus(callback) {
        // 不可聚焦，跳过
        if (!this._checkFocusable()) {
            callback && callback(false);
            return;
        }
        // 允许聚焦
        this._setChildFocus();
        callback && callback(true);
    }

    $validate(errorCallback) {
        if (!this._data.isMounted) {
            // 处于非挂载状态时，不验证
            return;
        }
        const { dataIndex, rowIndex, colTitle, record } = this.props;
        const curRecord = {
            ...this.props.record,
            [dataIndex]: this.context.getItemValue(rowIndex, dataIndex),
        };
        delete curRecord.key;
        const isRecordEmpty = Tools.isEmptyObject(curRecord, 'checkValue');
        // 如果没有验证器，或本行为空行，视为验证通过
        if (!this.$validator || isRecordEmpty) {
            this._setErrorMsg('');
            this.context.setItemValidated({
                passed: true,
                position: [record.key, dataIndex],
            });
            errorCallback && errorCallback(null);
            return;
        }
        // 验证
        this.$validator
            .validate({
                ...this.props.record,
                [dataIndex]: this.context.getItemValue(rowIndex, dataIndex),
                $cell: { dataIndex, rowIndex },
            })
            .then(
                res => {
                    this._setErrorMsg('');
                    this.context.setItemValidated({
                        passed: true,
                        position: [record.key, dataIndex],
                    });
                    errorCallback && errorCallback(null);
                },
                ({ errors, fields }) => {
                    this._setErrorMsg(errors[0].message.replace(dataIndex, colTitle));
                    this.context.setItemValidated({
                        passed: false,
                        position: [record.key, dataIndex],
                    });
                    const errorMsg =
                        errors[0].message.replace(dataIndex, colTitle) +
                        ' (第' +
                        (rowIndex + 1) +
                        '行)';
                    errorCallback &&
                        errorCallback({
                            rowIndex,
                            dataIndex,
                            errorMsg,
                        });
                },
            );
    }
}

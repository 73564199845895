/**
 * custom validate message
 */

export const validateMsgs = {
    default: '字段验证错误 %s',
    required: '请输入%s',
    enum: '%s必须是%s其中一个',
    whitespace: '%s不能为空字符',
    date: {
        format: '%s日期格式无效',
        parse: '%s不能转换为日期',
        invalid: '%s是一个无效日期',
    },
    types: {
        string: '%s不是一个有效的字符串',
        method: '%s不是一个有效的函数',
        array: '%s不是一个有效的数组',
        object: '%s不是一个有效的对象',
        number: '%s不是一个有效的数值',
        date: '%s不是一个有效的日期',
        boolean: '%s不是一个有效的布尔值',
        integer: '%s不是一个有效的整数',
        float: '%s不是一个有效的浮点数',
        regexp: '%s不是一个有效的正则表达式',
        email: '%s不是一个有效的邮件',
        url: '%s不是一个有效的网址',
        hex: '%s不是一个有效的16进制字符',
    },
    string: {
        len: '%s须为%s个字符',
        min: '%s最少%s个字符',
        max: '%s最多%s个字符',
        range: '%s字符数须在%s到%s之间',
    },
    number: {
        len: '%s必须等于%s',
        min: '%s最小值为%s',
        max: '%s最大值为%s',
        range: '%s须在%s到%s之间',
    },
    array: {
        len: '%s的数量必须等于%s',
        min: '%s的数量不能少于%s',
        max: '%s的数量不能超过%s',
        range: '%s数量须在%s到%s之间',
    },
    pattern: {
        mismatch: '%s输入格式有误',
    },
};

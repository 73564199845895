/**
 * CTableFormRowBtns 表格式表单-行操作按钮
 * @author Tevin、chensi
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, message, Popconfirm } from 'antd';
import { Tools } from '@components/common/Tools';
import { CIconRowAddNew } from '@components/fragments/icons/CIconRowAddNew';
import { CIconRowRemove } from '@components/fragments/icons/CIconRowRemove';
import { CIconRowInsert } from '@components/fragments/icons/CIconRowInsert';
import { CIconRowCopy } from '@components/fragments/icons/CIconRowCopy';
import { CIconRowClearAll } from '@components/fragments/icons/CIconRowClearAll';
import { CHelper } from '@components/fragments/helper/CHelper';
import './cTableFormTable.scss';

export class CTableFormRowBtns extends React.Component {
    static propTypes = {
        // 表格主数据
        dataResource: PropTypes.array,
        // 当前选中行序数
        selectedRowIndex: PropTypes.number,
        // 当前选中行数据
        selectedRowData: PropTypes.object,
        // 行操作按钮锁定
        selectedBtnLocked: PropTypes.object,
        // 数据清空是否锁定
        cleanAllLocked: PropTypes.bool,
        // 行操作按钮禁用
        rowBtnDisabled: PropTypes.bool,
        // 自定义更多行操作按钮
        rowBtnAddon: PropTypes.node,
        // 行数据变化回调
        onRowChange: PropTypes.func,
        // 行选中回调
        onRowSelected: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
    }

    // 添加行
    _handleAddRows() {
        const nextDatas = [...this.props.dataResource, { key: Tools.createGUID() }];
        this.props.onRowChange({
            type: 'addRow',
            lastIndex: this.props.selectedRowIndex,
            curIndex: nextDatas.length - 1,
            nextDatas,
        });
        // 选中新增的行
        this.props.onRowSelected({
            index: nextDatas.length - 1,
            record: nextDatas[nextDatas.length - 1],
            directly: 'automatic',
        });
        return nextDatas.length - 1;
    }

    _isRowSelected() {
        if (this.props.dataResource.length <= 0) {
            message.warn('请先添加数据！');
            return false;
        }
        if (
            this.props.selectedRowIndex === -1 ||
            Object.keys(this.props.selectedRowData).length <= 0
        ) {
            message.warn('请选择需要操作的行!');
            return false;
        }
        return true;
    }

    // 删除行
    _handleRemoveRows() {
        if (!this._isRowSelected()) {
            return;
        }
        const isLastRow =
            this.props.selectedRowIndex + 1 === this.props.dataResource.length;
        const nextDatas = [...this.props.dataResource];
        nextDatas.splice(this.props.selectedRowIndex, 1);
        // 如果删除最后一行，选中上一行
        if (isLastRow) {
            const nextIndex = this.props.selectedRowIndex - 1;
            this.props.onRowChange({
                type: 'removeRow',
                lastIndex: this.props.selectedRowIndex,
                curIndex: nextIndex,
                nextDatas,
            });
            this.props.onRowSelected({
                index: nextIndex,
                record: nextDatas[nextIndex],
                directly: 'automatic',
            });
        }
        // 删除非最后一行，取下一行数据，选中序数不变
        else {
            this.props.onRowChange({
                type: 'removeRow',
                lastIndex: this.props.selectedRowIndex,
                curIndex: this.props.selectedRowIndex,
                nextDatas,
            });
            this.props.onRowSelected({
                index: this.props.selectedRowIndex,
                record: nextDatas[this.props.selectedRowIndex],
                directly: 'automatic',
            });
        }
    }

    // 插入行
    _handleInsertRows() {
        if (!this._isRowSelected()) {
            return;
        }
        const nextDatas = [...this.props.dataResource];
        nextDatas.splice(this.props.selectedRowIndex, 0, { key: Tools.createGUID() });
        this.props.onRowChange({
            type: 'insertRow',
            lastIndex: this.props.selectedRowIndex,
            curIndex: this.props.selectedRowIndex,
            nextDatas,
        });
        // 选中插入的行
        this.props.onRowSelected({
            index: this.props.selectedRowIndex,
            record: nextDatas[this.props.selectedRowIndex],
            directly: 'automatic',
        });
    }

    // 复制行
    _handleCopyRows() {
        if (!this._isRowSelected()) {
            return;
        }
        const nextDatas = [...this.props.dataResource];
        const nextRecord = {
            ...this.props.dataResource[this.props.selectedRowIndex],
            key: Tools.createGUID(),
        };
        const nextIndex = this.props.selectedRowIndex + 1;
        nextDatas.splice(nextIndex, 0, nextRecord);
        this.props.onRowChange({
            type: 'copyRow',
            lastIndex: this.props.selectedRowIndex,
            curIndex: nextIndex,
            nextDatas,
        });
        // 选中复制出来的新行
        this.props.onRowSelected({
            index: nextIndex,
            record: nextDatas[nextIndex],
            directly: 'automatic',
        });
    }

    // 清空所有行
    _handleClearAllRows() {
        if (this.props.dataResource.length <= 0) {
            message.warn('当前无数据，无需清除');
            return;
        }
        this.props.onRowChange({
            type: 'clearAll',
            lastIndex: this.props.selectedRowIndex,
            curIndex: -1,
            nextDatas: [],
        });
        this.props.onRowSelected({
            index: -1,
            record: {},
            directly: 'automatic',
        });
    }

    _renderHelp() {
        return (
            <span className="c-table-form-header-help">
                &#12288;（
                <CHelper
                    tip={
                        <div>
                            <b>键盘操作说明</b>
                            <div>1. 使用回车键切换下一项</div>
                            <div>2. 使用 ↑ ↓ ← → 方向键来回切换</div>
                            <div>3. 使用空格键改变勾选框选中</div>
                        </div>
                    }
                >
                    操作说明
                </CHelper>
                ）
            </span>
        );
    }

    render() {
        return (
            <div className="c-table-form-header-btns">
                <Button
                    size="small"
                    disabled={
                        this.props.rowBtnDisabled || this.props.selectedBtnLocked.add
                    }
                    onClick={() => this._handleAddRows()}
                >
                    <CIconRowAddNew />
                    增行
                </Button>
                <Button
                    size="small"
                    disabled={
                        this.props.rowBtnDisabled || this.props.selectedBtnLocked.remove
                    }
                    onClick={() => this._handleRemoveRows()}
                >
                    <CIconRowRemove />
                    删行
                </Button>
                <Button
                    size="small"
                    disabled={
                        this.props.rowBtnDisabled || this.props.selectedBtnLocked.insert
                    }
                    onClick={() => this._handleInsertRows()}
                >
                    <CIconRowInsert />
                    插行
                </Button>
                <Button
                    size="small"
                    disabled={
                        this.props.rowBtnDisabled || this.props.selectedBtnLocked.copy
                    }
                    onClick={() => this._handleCopyRows()}
                >
                    <CIconRowCopy />
                    复制
                </Button>
                <Popconfirm
                    title="是否确认清空所有行？"
                    okText="确定"
                    cancelText="再想想"
                    disabled={this.props.rowBtnDisabled || this.props.cleanAllLocked}
                    onConfirm={() => this._handleClearAllRows()}
                >
                    <Button
                        size="small"
                        disabled={this.props.rowBtnDisabled || this.props.cleanAllLocked}
                    >
                        <CIconRowClearAll />
                        清空
                    </Button>
                </Popconfirm>
                {this.props.rowBtnAddon ? <>&#12288;{this.props.rowBtnAddon}</> : ''}
                {this._renderHelp()}
            </div>
        );
    }

    $addNew() {
        return this._handleAddRows();
    }
}

/**
 * CTableFormTable 表格式表单-表格体
 * @author Tevin、chensi
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Table, Tooltip } from 'antd';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CResizeableHead } from '@components/plugins/richTable/CResizeableHead';
import { CExpandableEllipsis } from '@components/plugins/richTable/CExpandableEllipsis';
import { CTableSummary } from '@components/plugins/richTable/CTableSummary';
import { Tools } from '@components/common/Tools';
import './cTableFormTable.scss';

export class CTableFormTable extends React.Component {
    static propTypes = {
        // 滚动高度
        scrollHeight: PropTypes.number,
        // 自动匹配最大高度
        autoMaxHeigh: PropTypes.bool,
        // 自动高度时，需要减去的额外高度
        trimHeight: PropTypes.number,
        // 表格标题
        title: PropTypes.func,
        // 表格列
        defaultColumns: PropTypes.array,
        // 表单验证失败的单元格
        errorCells: PropTypes.object,
        // 当前焦点单元格
        focusCell: PropTypes.array,
        // 加载中状态
        loading: PropTypes.bool,
        // 数据
        dataResource: PropTypes.array,
    };

    static defaultProps = {
        scrollHeight: 400,
        autoMaxHeigh: true,
        trimHeight: 0,
        loading: false,
        defaultColumns: [],
        errorCells: {},
        focusCell: [],
        dataResource: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            scrollHeight: this.props.scrollHeight,
            tableWidth: 0,
            mobScreen: window.innerWidth < 576,
        };
        this.$refs = {};
        this._listeners = {};
    }

    componentDidMount() {
        // 初始化列
        this._createColumns();
        // 自动最大高度
        this._listeners.onResize = () => {
            if (!this.props.autoMaxHeigh) {
                return;
            }
            if (!this.$refs.container) {
                return;
            }
            // 按需填充，填充到满屏为止
            const containerTop = this.$refs.container.getBoundingClientRect().y;
            // 表格顶部高度
            const $title = this.$refs.container.querySelector('.ant-table-title');
            const titleH = $title.getBoundingClientRect().height;
            // 标题高度
            let headH = 48;
            // 底部距离：内容区内边距 + 窗口区内边距
            const endSideH = 15 + 10;
            // 填充高度
            const fillHeight =
                window.innerHeight -
                containerTop -
                titleH -
                headH -
                endSideH -
                this.props.trimHeight;
            const minimumHeight = window.innerWidth >= 576 ? 300 : 355;
            this.setState({
                // 保留表格最小高度
                scrollHeight: Math.max(fillHeight, minimumHeight),
                // 是否为移动平台
                mobScreen: window.innerWidth < 576,
            });
        };
        setTimeout(this._listeners.onResize, 0);
        window.addEventListener('resize', this._listeners.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._listeners.onResize);
    }

    // 初始化列
    _createColumns() {
        let tableWidth = 0;
        const columns = this.props.defaultColumns
            .map((column, index) => {
                let column2 = this._createColumnBySingle(column, index);
                tableWidth += column2 ? column2.width : 0;
                return column2;
            })
            .filter(Boolean);
        this.setState({
            columns,
            tableWidth,
        });
    }

    // 检测列是否必填
    _checkColumnRequired(column) {
        let isRequired = false;
        if (column.rules && column.rules.length) {
            column.rules.forEach(rule => {
                if (rule.required) {
                    isRequired = true;
                }
            });
        }
        return isRequired;
    }

    // 标题单元格样式名
    _getHeaderCellClassName(column) {
        const nextClassName = column.className || '';
        const isRequired = this._checkColumnRequired(column);
        return nextClassName + ' ' + (isRequired ? 'c-table-form-header-required' : '');
    }

    // 内容单元格样式名
    _getCellClassName(recordKey, dataIndex) {
        let classNames = [];
        // 报错样式
        const errors = this.props.errorCells[recordKey];
        if (Tools.isArray(errors) && errors.indexOf(dataIndex) >= 0) {
            classNames.push('c-table-form-cell-error');
        }
        // 焦点样式
        if (
            recordKey === this.props.focusCell[0] &&
            dataIndex === this.props.focusCell[1]
        ) {
            classNames.push('c-table-form-cell-focus');
        }
        return classNames.join(' ');
    }

    // 重设列配置（仅支持一级标题）
    _createColumnBySingle(column, index) {
        const that = this;
        // 列标题宽度 = 转换后的字符个数 * 7 + 单元格内边距 + 帮助图标宽度 + 必填图标宽度
        let headerWidth =
            (String(column.title) || '').replace(/[\u4e00-\u9fa5]/g, 'aa').length * 7;
        headerWidth += typeof column.helper === 'undefined' || column.helper ? 20 : 0;
        headerWidth += this._checkColumnRequired(column) ? 12 : 0;
        // 重设列配置
        const column2 = {
            width: Math.max(100, headerWidth),
            align: 'center', // 默认居中
            fixed: false, // 默认不开启固定
            sorter: false, // 默认关闭排序
            ellipsis: true, // 默认开启省略
            resizeable: true, // 默认开启缩放
            ...column,
            title: this._renderColTitleHelper(column),
            key: 'col-' + index,
            onHeaderCell: curColumn => ({
                className: that._getHeaderCellClassName(column),
                colIndex: index,
                width: curColumn.width,
                fixed: curColumn.fixed,
                resizeable: curColumn.resizeable,
                onResize: that._handleResize(index),
                onFixedChange: that._handleFixed(index),
            }),
            onCell: (record, rowIndex) => ({
                isFormItem: !!column.isFormItem,
                className: that._getCellClassName(record.key, column.dataIndex),
            }),
        };
        // 移动端关闭固定
        if (this.state.mobScreen) {
            column2.fixed = false;
        }
        return column2;
    }

    // 处理拖拽调整列大小的问题
    _handleResize(index) {
        return (evt, resizeData) => {
            const nextColumns = [...this.state.columns];
            if (resizeData.width === nextColumns[index].width) {
                return;
            }
            nextColumns[index] = {
                ...nextColumns[index],
                width: resizeData.width || nextColumns[index].width,
            };
            this.setState({
                columns: nextColumns,
            });
        };
    }

    // 处理设定所有列宽度下，固定显示的列的悬浮层，宽度不够的问题
    //  当表格被“拉宽”时，取消固定列的悬浮层显示
    _handleFixed(index) {
        return (evt, fixed) => {
            const nextColumns = [...this.state.columns];
            nextColumns[index] = {
                ...nextColumns[index],
                fixed,
            };
            this.setState({
                columns: nextColumns,
            });
        };
    }

    // 显示帮助信息
    _renderColTitleHelper(col) {
        if (!col.helper) {
            return col.title;
        } else {
            return (
                <span className="c-table-title-helper">
                    <Tooltip placement="top" title={col.helper}>
                        {col.title}
                        <QuestionCircleOutlined />
                    </Tooltip>
                </span>
            );
        }
    }

    _renderTableSummary(datas) {
        return (
            <CTableSummary
                defaultColumns={this.props.defaultColumns}
                columns={this.state.columns}
                datas={datas}
                hasSelection={!!this.props.rowSelection}
            />
        );
    }

    render() {
        const components = {
            header: { cell: CResizeableHead },
            body: { cell: CExpandableEllipsis },
        };
        const tableLoading = {
            indicator: <LoadingOutlined spin />,
            spinning: this.props.loading,
        };
        const tableScroll = {
            x: this.state.tableWidth, // 表格计算宽度
            y: this.state.scrollHeight, // 最大填充至满屏
        };
        const {
            defaultColumns,
            autoMaxHeigh,
            title,
            dataResource,
            loading,
            ...resetProps
        } = this.props;
        return (
            <div className="c-table-form-table" ref={elm => (this.$refs.container = elm)}>
                <Table
                    {...resetProps}
                    size="small"
                    bordered
                    pagination={false}
                    showSorterTooltip={false}
                    components={components}
                    loading={tableLoading}
                    scroll={tableScroll}
                    tableWidth={this.state.tableWidth}
                    title={title}
                    columns={this.state.columns}
                    rowKey={row => row.id || row.key}
                    dataSource={dataResource}
                    summary={evt => this._renderTableSummary(evt)}
                />
            </div>
        );
    }
}

/**
 * CInputCalcable - 可计算输入框
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Input, Tooltip } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';

export class CInputCalcable extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 行内样式
        style: PropTypes.object,
        // 输入框清空按钮
        allowClear: PropTypes.bool,
        // 是否禁用
        disabled: PropTypes.bool,
        // 占位提示
        placeholder: PropTypes.string,
        // 初始项值
        value: PropTypes.any,
        // 改变项值后的回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            // 提示显示
            isTipOpened: false,
            // 提示内容
            tipResult: '',
            // 是否聚焦
            isFocusing: false,
            // 正在输入的算式
            word: '',
        };
        this.$refs = {
            input: null,
        };
        this._data = {
            // 显示提示是说明状态
            isTipDesc: false,
        };
        this._listener = {
            onFocus: null,
            onBlur: null,
        };
    }

    componentDidMount() {
        this.input = this.$refs.input.input;
        this._listener.onFocus = evt => {
            this.setState({
                isFocusing: true,
                // 算式默认为当前的值
                word: this.props.value,
            });
        };
        this._listener.onBlur = evt => {
            this.setState({
                isFocusing: false,
            });
            this._handleBackFill(evt.target.value);
        };
        this.input.addEventListener('focus', this._listener.onFocus);
        this.input.addEventListener('blur', this._listener.onBlur);
    }

    componentWillUnmount() {
        this.input.removeEventListener('focus', this._listener.onFocus);
        this.input.removeEventListener('blur', this._listener.onBlur);
        this.input = null;
    }

    // 失去焦点后回显
    _handleBackFill(value) {
        const calcData = this._checkInputIsFormula(value);
        // 算式，填回结果
        if (calcData.state === 'formula') {
            this.props.onChange(calcData.value + '');
        }
        // 无效删除值
        else if (calcData.state === 'invalid') {
            this.props.onChange('');
        }
        // 关闭提示
        this.setState({
            isTipOpened: false,
        });
    }

    // 输入
    _handleInputChange(evt) {
        // 计算
        const value = evt.target.value;
        if (value) {
            const calcData = this._checkInputIsFormula(value);
            // 纯数字，不显示提示
            if (calcData.state === 'number') {
                this.setState({
                    isTipOpened: false,
                    word: value,
                });
                // 回调直接传输入内容
                this.props.onChange(evt.target.value);
            }
            // 算式，显示计算结果
            else if (calcData.state === 'formula') {
                this.setState({
                    isTipOpened: true,
                    tipResult: '计算结果：' + calcData.value,
                    word: value,
                });
                // 回调传递计算后的值
                this.props.onChange(calcData.value + '');
            }
            // 无效输入
            else if (calcData.state === 'invalid') {
                this.setState({
                    isTipOpened: true,
                    tipResult: '无效输入',
                    word: value,
                });
                // 回调传递空值
                this.props.onChange('');
            }
        }
        // 清空
        else {
            this.setState({
                isTipOpened: false,
                word: value,
            });
            // 回调传递空值
            this.props.onChange('');
        }
    }

    // 检查输入是否为算式
    _checkInputIsFormula(word) {
        // 纯数字
        if (/^-?\d+(\.\d+)?$/.test(word)) {
            return { state: 'number', word, value: Number(word) };
        }
        // 有计算符号
        else {
            // 有效输入
            if (/^\d+([+\-*/.]\d+)*$/.test(word)) {
                try {
                    const evalVal = fn => {
                        const Fn = Function;
                        return new Fn('return ' + fn)();
                    };
                    const value = evalVal(word);
                    if (isNaN(value)) {
                        return { state: 'invalid' };
                    }
                    return {
                        state: 'formula',
                        word,
                        value: Number(value.toFixed(2)),
                    };
                } catch (e) {
                    return { state: 'invalid' };
                }
            }
            // 无效输入
            else {
                return { state: 'invalid' };
            }
        }
    }

    // 设置提示为使用说明
    _handleInputDescription(type) {
        // 显示说明
        if (type === 'show') {
            // 禁用跳过
            if (this.props.disabled) {
                return;
            }
            // 已经打开跳过
            if (this.state.isTipOpened) {
                return;
            }
            // 显示提示，并将内容设置为使用说明
            this.setState({
                isTipOpened: true,
                tipResult: '可计算输入框，输入 18/3 可得 6',
            });
            // 标记说明状态
            this._data.isTipDesc = true;
        }
        // 隐藏说明
        else if (type === 'hide') {
            // 不是说明状态跳过
            if (!this._data.isTipDesc) {
                return;
            }
            // 隐藏提示
            this.setState({
                isTipOpened: false,
            });
            // 标记非说明状态
            this._data.isTipDesc = false;
        }
    }

    render() {
        const { suffix, ...resetProps } = this.props;
        const suffix2 = (
            <>
                {this.props.suffix ? this.props.suffix : null}
                <CalculatorOutlined
                    style={{
                        marginLeft: this.props.suffix ? 5 : 0,
                        color: 'rgba(0, 0, 0, 0.25)',
                    }}
                    onMouseEnter={evt => this._handleInputDescription('show')}
                    onMouseLeave={evt => this._handleInputDescription('hide')}
                />
            </>
        );
        return (
            <Tooltip title={this.state.tipResult} visible={this.state.isTipOpened}>
                <Input
                    {...resetProps}
                    className={['c-input-calcable', this.props.className].join(' ')}
                    value={this.state.isFocusing ? this.state.word : this.props.value}
                    allowClear={this.props.allowClear}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    suffix={suffix2}
                    ref={elm => (this.$refs.input = elm)}
                    onChange={evt => this._handleInputChange(evt)}
                />
            </Tooltip>
        );
    }

    $focus() {
        this.$refs.input.focus();
    }

    $blur() {
        this.$refs.input.blur();
    }

    focus() {
        this.$focus();
    }

    blur() {
        this.$blur();
    }
}
